@media screen and (max-width: 3300px) {
  .portfolio__item {
    -webkit-flex-basis: 16.66%;
        -ms-flex-preferred-size: 16.66%;
            flex-basis: 16.66%; } }

@media screen and (max-width: 2545px) {
  .catalog__wrapp, .portfolio__item {
    -webkit-flex-basis: 25%;
        -ms-flex-preferred-size: 25%;
            flex-basis: 25%; } }

@media screen and (max-width: 1520px) {
  .boxmodal {
    width: 95vw;
    padding: 50px; } }

@media screen and (max-width: 1430px) {
  .portfolio__item {
    -webkit-flex-basis: 33.33%;
        -ms-flex-preferred-size: 33.33%;
            flex-basis: 33.33%; } }

@media screen and (max-width: 1550px) {
  .portfolio__img {
    height: 275px; } }

@media screen and (max-width: 1300px) {
  .fa-arrow-circle-left {
    left: -34px; }
  .fa-arrow-circle-right {
    right: -34px; }
  .slider__wrapper {
    padding: 0 30px; } }

@media screen and (max-width: 1256px) {
  .catalog__wrapp {
    -webkit-flex-basis: 33.33%;
        -ms-flex-preferred-size: 33.33%;
            flex-basis: 33.33%; }
  .catalog__item {
    padding-top: 50px;
    padding-bottom: 43px; } }

@media screen and (max-width: 1200px) {
  .headerf {
    display: none !important; }
  .header2f {
    display: block !important; }
    .header2f .nav__list {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; }
    .header2f .nav__list_wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
    .header2f .nav__item {
      margin-right: 0;
      margin-left: 0; }
    .header2f .nav__item_m {
      margin-left: 25px; }
    .header2f .contact__list {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start; }
    .header2f .header__wrapper1, .header2f .header__wrapper2 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center; }
    .header2f .nav {
      margin-bottom: 0; }
    .header2f .contact__item {
      margin: 0; }
  .dor__img {
    width: 290px;
    height: 290px; }
  .dor__text {
    margin-bottom: 0; }
  .dor__wrap2 {
    padding-top: 30px;
    padding-bottom: 20px;
    display: block; }
  .dor__wrap1 {
    display: none; }
  .dor__item_wrapper {
    margin-bottom: 30px; }
  .img_certificate {
    width: 170px;
    height: 220px; }
  .nav__item {
    margin: 0px 14px; }
  .contact__item {
    margin: 0 16px; }
  .mfp-close {
    width: 38px;
    height: 38px; }
  .work__sect1 {
    padding-top: 15px;
    padding-right: 30px; }
  .form__wrapp {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; } }

@media screen and (max-width: 1100px) {
  .slider__img {
    height: 350px;
    width: 100%; } }

@media screen and (max-width: 991px) {
  .dor__img {
    width: 330px;
    height: 330px; }
  .img_certificate {
    width: 170px;
    height: 200px; }
  .portfolio__item {
    -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
            flex-basis: 50%; }
  .about__sect {
    width: 100%; }
  .about__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center !important;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #32363b; }
  .about__sect1 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .abuot__text {
    max-width: 100%;
    line-height: 1.6; }
  .about__decor {
    display: none; }
  .about__title {
    margin-top: 50px; }
  .about__sect2, .about__sect3 {
    padding: 0 0 45px;
    padding-left: 15px;
    padding-right: 15px; }
  .catalog__wrapp {
    -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
            flex-basis: 50%; }
  .first__decor_left, .first__decor_right {
    width: 19.7%; }
  .header {
    display: none; }
  .about__sect3 {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .about__text2 {
    text-align: left; }
  .about__img2, .about__img {
    min-height: 1px; }
  .header2 {
    display: block; }
  .nav__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .nav__list_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .nav__item {
    margin-right: 0;
    margin-left: 0; }
  .nav__item_m {
    margin-left: 25px; }
  .contact__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .header__wrapper1, .header__wrapper2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .nav {
    margin-bottom: 0; }
  .contact__item {
    margin: 0; }
  .adv__list {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .adv__item {
    margin-bottom: 23px; }
  .adv__info {
    line-height: 1.6; }
  .adv {
    padding-top: 53px;
    padding-bottom: 53px; }
  h2 {
    line-height: 1.6; } }

@media screen and (max-width: 900px) {
  .pfirst__wrapper .single-item {
    margin-bottom: 81px; }
  .pfirst__title_s {
    text-align: center !important;
    display: block; }
  .pfirst__title_f {
    display: none; }
  .pfirst__sect2 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
        -ms-flex-order: 1;
            order: 1; }
  .pfirst__sect1 {
    padding-right: 0px;
    text-align: center;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
        -ms-flex-order: 2;
            order: 2; }
  .pfirst__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
  .pfirst__sect {
    width: 100%; }
  .work__sect {
    width: 100%;
    padding-top: 0;
    padding-right: 0; }
  .work__sect1 {
    margin-bottom: 20px; }
  .slider__img {
    height: 450px;
    width: 100%; }
  .work {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; } }

@media screen and (max-width: 767px) {
  .dor__img {
    width: 510px;
    height: 510px; }
  .inform__text2 {
    min-width: 15%;
    text-align: right; }
  .inform__text1 {
    width: auto; }
  .inform__item {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .slick-dots li {
    margin: 0 8px; }
  .about {
    color: #fff;
    padding-top: 0px;
    padding-bottom: 34px; }
  .img_certificate {
    width: 170px;
    height: 180px; }
  .button__portfolio {
    margin-top: 49px; }
  a.portfolio__item:nth-of-type(n+5) {
    display: none; }
  .button__portfolio2 {
    display: none; }
  .portfolio__active a.portfolio__item:nth-of-type(n+5) {
    display: block; }
  .portfolio__active .button__portfolio2 {
    display: block; }
  .portfolio__active .button__portfolio1 {
    display: none; }
  .portfolio__item {
    padding-bottom: 0;
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 1px; }
  .center__title, h2 {
    font-size: 24px; }
  .center {
    padding: 55px 0 60px; }
  .arcticmodal-container_i2 {
    padding: 0;
    padding-top: 24px; }
  .title__dicor {
    display: block; }
  .first__decor_left, .first__decor_right {
    width: 10.7%; }
  .dor__center {
    padding: 44px 0 24px; } }

@media screen and (max-width: 628px) {
  .catalog__wrapp {
    -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; } }

@media screen and (max-width: 574px) {
  body > section > div.portfolio__block > div > div > div > div.sork__sect2.work__sect > div > i.fa.fa-arrow-circle-left.certificat__fa.slick-arrow
.pfirst .fa-arrow-circle-right, .portfolio .fa-arrow-circle-right, .portfolio__block .fa-arrow-circle-right {
    right: 33%; }
  .pfirst .fa-arrow-circle-left, .portfolio .fa-arrow-circle-left, .portfolio__block .fa-arrow-circle-left {
    left: 33%; }
  .img_certificate {
    width: 170px;
    height: 240px; }
  .certificate__img {
    padding: 0 1.5px; }
  .img_certificate {
    margin: 0 auto; }
  .logo__left {
    margin-left: -15px; }
  .logo__link {
    margin-right: -15px; }
  .slider__img {
    height: 350px;
    width: 100%; }
  .contact__item2 {
    display: none; } }

@media screen and (max-width: 424px) {
  .footer .logo__left {
    margin-left: -15px;
    margin-top: -3px; }
  .slider__img {
    height: 230px;
    width: 100%; }
  .contact__item, .contact__link {
    text-align: left; }
  .nav__link {
    text-align: right; }
  .nav__list {
    padding: 10px 0; }
  .logo__left {
    margin-left: -15px;
    margin-top: -9px; }
  .nav__list_wrapper {
    display: block;
    text-align: right; }
  .header {
    padding-top: 0; }
  .circs {
    padding-top: 66px;
    padding-bottom: 51px; }
  .adv {
    padding: 66px 0 41px; }
  .form__input {
    margin-left: 0;
    margin-right: 0;
    padding-left: 15px;
    font-size: 16px; }
  .first__titile {
    display: 35px; }
  .first__decor_left, .first__decor_right {
    display: none; } }

@media screen and (max-width: 370px) {
  .img_certificate {
    width: 170px;
    height: 180px; } }

@media screen and (max-width: 376px) {
  .logo__left {
    margin-left: -15px;
    margin-top: -28px; }
  .footer .logo__left {
    margin-left: -15px;
    margin-top: 0px; }
  .circs__item {
    padding: 40px 19px 31px; } }

@media screen and (max-width: 343px) {
  h2 {
    font-size: 22px; } }

@media only screen and (-Webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5) {
  .img__retina {
    background-image: url(../img/sprite2.png);
    -webkit-background-size: 430px 360px;
            background-size: 430px 360px; } }
